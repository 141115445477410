import React, { useState, useEffect } from "react";
import ForgotPassword from "../common/forgot";
import { useLocation } from "react-router-dom";
import Giff from '../image/mob.gif'
// import DataToya from '../toya.json'
import { useHistory } from "react-router-dom";
import DataToya from "../data.json";
import { Redirect } from "react-router-dom";
import axios from "axios";
export default function Homepage() {
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullNameEdit, setFullNameEdit] = useState(true);
  const [loginStatusCode, setLoginStatusCode] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [userName, setUserName] = useState("");
  const [memberIdError, setMemberIdError] = useState("");

  const [memberId, setMemberId] = useState("");
  const [showPass, setPassShow] = useState("");
  const [showPass1, setPassShow1] = useState("");

  const [loginPass, setLoginPass] = useState("");
  const [loginEmail, setLoginEmail] = useState("");

  const [showConfPass, setConfPassShow] = useState("");
  const [password, setPassword] = useState("");
  const [cnfPassword, setCnfPassword] = useState("");
  const [alreadyerror, showAlreadyError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [counter, setCounter] = useState(
    window.innerHeight > window.innerWidth ? "landscape" : "oriential"
  );
  const [out, setOut] = useState(0);
  
 useEffect( () => {
  const timer = setTimeout(async() => {
setCounter(  window.innerHeight > window.innerWidth ? "landscape" : "oriential")
setOut(out+1)
// alert(8)
  },1000);
  return () => clearTimeout(timer);
}, [out])
  const deviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };

  const [land, setLand] = useState(deviceType());

  const history = useHistory();
  function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  }
  const Phoncheck = /^[0-9\b]+$/;
  var passwordCheck =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  var reEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
  function emailCheck() {
    console.log("cvbnm====>>>>>>>>>>", reEmail.test(email));
  }


// alert(88)

const handleRegistration = async () => {
    setShowSuccess(false);
    showAlreadyError(false);

    let response = await axios.post(
      "https://jllpartnersummit2021.virtuallive.in:3002/regsiter",
      {
        userPassword: password,
        userEmail: email,
        userName: fullName,
      }
    );
    console.log("=>>>>>>>>", response.data);

    if (response.data.statusMsj) {
      showAlreadyError(true);
      setUserName("");
      setFullName("");
      setFullNameEdit(true);
      setEmail("");
      setCnfPassword("");
      setPassword("");
    }

    if (response.data._id) {
      setShowSuccess(true);
      setFullNameEdit(true);
      setUserName("");
      setEmail("");
      setFullName("");
      setCnfPassword("");
      setPassword("");
    }
  };
  const location = useLocation();

  // useEffect(() => {
  //   const script = document.createElement("script");

  //   script.src = "https://embed.tawk.to/60dacd3f65b7290ac63871e4/1f9bb3gu7";
  //   script.async = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     // alert(6)
  //     document.body.removeChild(script);
  //   };
  // }, []);

  const handleLogin = async () => {

    // alert(8)
console.log("4567890")
  
    // const dat2=Datajson.Sheet2.find( ({ memid }) => memid === memberId )

    // const dat2 = Datajson.Sheet2.find(({ memid }) => memid === memberId);
   const ff= DataToya.Sheet2.find(o => o.userEmail.toLocaleLowerCase() === loginEmail.toLocaleLowerCase())
console.log("4567890",ff)

   if(ff){
    window.location.href = "/live";
    localStorage.setItem("userEmail", ff.userEmail);

      localStorage.setItem("userName", ff.userName);

   }
    let response = await axios.post(
      "http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3001/findoneuser",
      {
        userEmail: loginEmail,
       
      }
    );

    // console.log("12345678=>>>>>>>>", dat2);
  //  console.log("=>>>>>>>>",response.data)

    if (response.status == 200) {
      localStorage.setItem("userEmail", response.data.userEmail);

      localStorage.setItem("userName", response.data.userName);

        window.location.href = "/live";
    } else {
      setLoginStatusCode('Email address not found');
    }
  };


  const handleChange = (value) => {
    if (value === "" || Phoncheck.test(value)) {
      setMemberId(value);

      setMemberIdError("");
    } else {
      setMemberIdError("");
    }
  };



  const passwordError =
    "Must contain at least one number, one uppercase and lowercase letter, one special character, and Min 8 or more characters.";
  const cnfPassError = "Password did not match";
  const emailError = "Enter valid Email Id ";
  const nameError = "Enter Vaild Name";
  // console.log("cvbnm====>>>>>>>>>>",  reEmail.test(email), email !== "");
  return (
    land !== "mobile" ? (   <div
      className={`flex flex-column items-center justify-center ${
        window.innerHeight > 900
          ? "backgroundLoginbiglaptop img-fluid"
          : window.innerHeight > 700
          ? "backgroundLoginMedium"
          : "backgroundLoginBigcss"
      }`}
    >






      {!forgotPassword ? (
        <div
          className={`w-95  flex flex items-center justify-center ${
            window.innerHeight > 900
              ? "m"
              : window.innerHeight > 700
              ? ""
              : "nt4"
          }`}
        >
          {/* <div className="flex w-70-l w-70 mt2 dn db-l "></div> */}
          <div className=" w-100    flex items-center justify-center">
            {/* <div className="flex w-100 navy fw6 ">Login</div> */}
            <div className="flex w-100 mt6  flex items-center justify-center  flex-column mt2">
              <div className="flex flex items-center justify-end flex-column  w-100 mt1 ">
                <div className="fw6 f4 toya"> LOGIN</div>
                <div className="flex flex-column w-30-l w-40">
                  <input
                    className="w-100-l ttl   mt2 pa1 "
                    type="text"
                    value={loginEmail}
                    onChange={(e) => (
                      setLoginEmail(e.target.value), setLoginStatusCode("")
                    )}
                    placeholder="Enter Email id "
                  />
                  <p className="red fw6 f5 lh-copy">
                    {reEmail.test(loginEmail) || loginEmail == ""
                      ? ""
                      : emailError}
                  </p>
                  <div className="flex w-100 mv2">
                  <input type="checkbox" />{" "}
                  <p className=" ml2 mt2 f6 fw6 ">Remember me</p>{" "}
                </div>
                </div>
                {/* sssss */}

                {/* b */}

                {memberIdError && (
                  <div className="flex f7 mt2   mb2  ttu red fw8 items-start justify-start w-100 pointer">
                    {memberIdError}
                  </div>
                )}
                
                {/* <div className="flex f7  mt2 mb2  ttu red fw8 items-end justify-end w-100 pointer" onClick={()=>setForgotPassword(true)}>
                forgot password
              </div> */}

                <div className="w-25 flex items-center justify-center">
                  <button
                   onClick={()=>handleLogin()}
                    // onClick={() =>reEmail.test(loginEmail) && loginPass? handleLogin(): null
                    // }
                    style={{
                      opacity: reEmail.test(loginEmail) && loginPass ? 1 : 1,
                    }}
                    className="w-70 pa2 br2 bg-red mt2  borderZero white pointer"
                  >
                    Login
                  </button>
                </div>
                <div className="mt2  fw6 flex items-center justify-center red">
                  {loginStatusCode !== "" && loginStatusCode}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ForgotPassword email={loginEmail} />
      )}
    </div>):( <div
      
    >
      {!forgotPassword ? (
      counter=="landscape" ? (
        <div className=" pa2 flex flex-column w-100 min-vh-80 items-center justify-center tc">
            <p>      <img src={Giff} className="h4" alt="" srcset="" /></p>
          <p className="mv2">Please Rotate your device</p><p>For a better experience, please view on a desktop/laptop screen.
            <br/>
           Some functionalities might not work properly in mobile devices.

        </p> 
      

        </div>  
      ) : <div className={`flex flex-column items-center justify-center ${
        window.innerHeight > 900
          ? "backgroundLoginBigcss img-fluid"
          : window.innerHeight > 700
          ? "backgroundLoginBigcss"
          : "backgroundLoginBigcss"
      }`}> <div
          className={`w-95  flex flex items-center justify-center ${
            window.innerHeight > 900
              ? "m"
              : window.innerHeight > 700
              ? ""
              : "nt4"
          }`}
        >
          {/* <div className="flex w-70-l w-70 mt2 dn db-l "></div> */}
          <div className=" w-100    flex items-center justify-center">
            {/* <div className="flex w-100 navy fw6 ">Login</div> */}
            <div className="flex w-100 mt6  flex items-center justify-center  flex-column mt2">
              <div className="flex flex items-center justify-center flex-column  w-100 mt1 ">
                <div className="fw6 f4 toya"> LOGIN</div>
                <div className="flex flex-column w-30-l w-40">
                  <input
                    className="w-100-l  ttl  mt2 pa1 "
                    type="text"
                    value={loginEmail}
                    onChange={(e) => (
                      setLoginEmail(e.target.value), setLoginStatusCode("")
                    )}
                    placeholder="Enter Email id "
                  />
                  <p className="red fw6 f5 lh-copy">
                    {reEmail.test(loginEmail) || loginEmail == ""
                      ? ""
                      : emailError}
                  </p>
                  <div className="flex w-100">
                  <input type="checkbox" />{" "}
                  <p className=" ml2 mt2 f6 fw6 ">Remember me</p>{" "}
                </div>
                </div>
                {/* sssss */}

                {/* b */}

                {memberIdError && (
                  <div className="flex f7 mt2   mb2  ttu red fw8 items-start justify-start w-100 pointer">
                    {memberIdError}
                  </div>
                )}
                
                {/* <div className="flex f7  mt2 mb2  ttu red fw8 items-end justify-end w-100 pointer" onClick={()=>setForgotPassword(true)}>
                forgot password
              </div> */}

                <div className="w-25 flex items-center justify-center">
                  <button
                 onClick={()=>handleLogin()}
                    // onClick={() =>reEmail.test(loginEmail) && loginPass? handleLogin(): null
                    // }
                    style={{
                      opacity: reEmail.test(loginEmail) && loginPass ? 1 : 1,
                    }}
                    className="w-70 pa2 br2 bg-red mt2  borderZero white pointer"
                  >
                    Login
                  </button>
                </div>
                <div className="mt2  fw6 flex items-center justify-center red">
                  {loginStatusCode !== "" && loginStatusCode}
                </div>
              </div>
            </div>
          </div>
        </div>
        </div> 
      ) : (
        <ForgotPassword email={loginEmail} />
      )}
    </div>))

    
  
}
